/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.spacer {
  flex: 1 1 auto;
}


//#region ESTILOS PARA EL NOMBRE DE AMBIENTE EN EL MENU
.user-info {
  display: flex;
  flex-direction: column-reverse !important;
}

.user-info div {
  color: rgba($color: #FFF, $alpha: 0.5) !important;
}

.user-info div ~ div {
  color: #FFF !important;
}

//#endregion


//#region ESTILOS PARA ALERTAS


//#endregion


//#region ESTILOS PARA LOS SCROLLBAR
::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f161;
}

::-webkit-scrollbar-thumb {
  background: #276ba0;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

//#endregion

/******* Reduce la altura de los inputs*******/
mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix {
  padding: 0.4em 0px
}

mat-form-field.mat-form-field.mat-form-field-appearance-outline > div.mat-form-field-wrapper > div.mat-form-field-flex > div.mat-form-field-infix > span.mat-form-field-label-wrapper {
  top: -1.4em;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.1em) scale(.75);
  width: 133.33333%;
}

/******* Reduce el padding al bajo los inputs *******/
//.mat-form-field-wrapper {
////  padding-bottom: 0.2rem !important;
////}


// Styles for buttons into mat-tabs
.mat-tab-group-company {

  .mat-tab-header {
    max-width: 90%;
  }
}


.mat-tab-header .mat-tab-label:has(> .mat-tab-label-content .first-button) {
  margin-left: auto;
  max-width: 80%;
}

:host .mat-tab-header {
  top: 0;
  z-index: 1000;
  position: sticky;
  position: -webkit-sticky;
  /* macOS/iOS Safari */
}

.mat-tab-labels .mat-tab-label:has(> .mat-tab-label-content .action-button) {
  min-width: 0;
  padding: 0 12px;
  color: unset;
  opacity: unset;
}

// End styles for mat-tabs

// LOGIN STYLES START
// This styles are to center the login component
div:has(> .mat-card.container.mt-5) {
  justify-content: center;
  align-items: center;
  height: 100vh;
}

// LOGIN STYLES END


// Styles for buttons and icons
table .mat-icon {
  color: #1980bd !important;
}

.mat-flat-button.mat-primary.mat-button-disabled,
.mat-flat-button.mat-accent.mat-button-disabled,
.mat-flat-button.mat-warn.mat-button-disabled,
.mat-flat-button.mat-button-disabled.mat-button-disabled,
.mat-raised-button.mat-primary.mat-button-disabled,
.mat-raised-button.mat-accent.mat-button-disabled,
.mat-raised-button.mat-warn.mat-button-disabled,
.mat-raised-button.mat-button-disabled.mat-button-disabled,
.mat-fab.mat-primary.mat-button-disabled,
.mat-fab.mat-accent.mat-button-disabled,
.mat-fab.mat-warn.mat-button-disabled,
.mat-fab.mat-button-disabled.mat-button-disabled,
.mat-mini-fab.mat-primary.mat-button-disabled,
.mat-mini-fab.mat-accent.mat-button-disabled,
.mat-mini-fab.mat-warn.mat-button-disabled,
.mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, .12) !important;
}


.mat-flat-button {
  background-color: #0082c0 !important;
  color: white !important;
}




.table-overflow {
  .mat-table {
    min-width: 500px;

    .th, td {
      // width: 100px !important;
      white-space: nowrap;
    }
  }
}



// End styles for buttons and icons

/*ESTILOS DE LOS FIELDSET*/

fieldset {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 10px 20px;
  min-inline-size: auto;

  legend {
    margin-top: -10px;
    font-weight: bold;
  }
}

/*GENERAR SCROLL EN PAGINADOR Y TOTALES PARA VER BIEN EL CONTENIDO*/

.mat-paginator-outer-container {
  overflow: auto;

  .mat-paginator-container {
    width: 100%;
    min-width: 550px;
  }
}

.amount-responsive {
  overflow: auto;

  .amount {
    width: 100%;
    min-width: 700px;
  }
}

/*CAMBIO DE COLORES DEL SLIDE TOOGGLE*/
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #0082C0;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #1C9FD4;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-ripple .mat-ripple-element {
  background-color: #1C9FD4
}

/*CAMBIAR EL COLOR DE LOS CHECKBOX*/
.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #0082C0 !important;
}

/*CAMBIAR COLOR DE LOS RADIOS*/
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #0082C0 !important;
}


.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0082C0 !important;
}

.mat-mini-fab.mat-accent {
  background-color: #0082C0;
}


/* REGION DE GRID */
.grid-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  grid-gap: 10px;
}

.grid-container-printer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  grid-gap: 2px;
}

.col-lg-1 {
  grid-column: span 1;
}

.col-lg-2 {
  grid-column: span 2;
}

.col-lg-3 {
  grid-column: span 3;
}

.col-lg-4 {
  grid-column: span 4;
}

.col-lg-5 {
  grid-column: span 5;
}

.col-lg-6 {
  grid-column: span 6;
}

.col-lg-7 {
  grid-column: span 7;
}

.col-lg-8 {
  grid-column: span 8;
}

.col-lg-9 {
  grid-column: span 9;
}

.col-lg-10 {
  grid-column: span 10;

}

.col-lg-11 {
  grid-column: span 11;
}

.col-lg-12 {
  grid-column: span 12;
}

@media (max-width: 1950px) {
  .col-xs-12 {
    grid-column: span 12;
  }

}

@media (max-width: 1550px) {
  .col-lt-lg-1 {
    grid-column: span 1;
  }
  .col-lt-lg-2 {
    grid-column: span 2;
  }
  .col-lt-lg-3 {
    grid-column: span 3;
  }
  .col-lt-lg-4 {
    grid-column: span 4;
  }
  .col-lt-lg-5 {
    grid-column: span 5;
  }
  .col-lt-lg-6 {
    grid-column: span 6;
  }
  .col-lt-lg-7 {
    grid-column: span 7;
  }
  .col-lt-lg-8 {
    grid-column: span 8;
  }
  .col-lt-lg-9 {
    grid-column: span 9;
  }
  .col-lt-lg-10 {
    grid-column: span 10;
  }
  .col-lt-lg-11 {
    grid-column: span 11;
  }
  .col-lt-lg-12 {
    grid-column: span 12;
  }
}

@media (max-width: 1265px) {
  .col-gt-md-1 {
    grid-column: span 1;
  }

  .col-gt-md-2 {
    grid-column: span 2;
  }

  .col-gt-md-3 {
    grid-column: span 3;
  }

  .col-gt-md-4 {
    grid-column: span 4;
  }

  .col-gt-md-5 {
    grid-column: span 5;
  }

  .col-gt-md-6 {
    grid-column: span 6;
  }

  .col-gt-md-7 {
    grid-column: span 7;
  }

  .col-gt-md-8 {
    grid-column: span 8;
  }

  .col-gt-md-9 {
    grid-column: span 9;
  }

  .col-gt-md-10 {
    grid-column: span 10;
  }

  .col-gt-md-11 {
    grid-column: span 11;
  }

  .col-gt-md-12 {
    grid-column: span 12;
  }
}


@media (max-width: 1130px) {  //antes estaba en 1024 pero se cambio por un tema de resposnsive
  .col-md-1 {
    grid-column: span 1;
  }

  .col-md-2 {
    grid-column: span 2;
  }

  .col-md-3 {
    grid-column: span 3;
  }

  .col-md-4 {
    grid-column: span 4;
  }

  .col-md-5 {
    grid-column: span 5;
  }

  .col-md-6 {
    grid-column: span 6;
  }

  .col-md-7 {
    grid-column: span 7;
  }

  .col-md-8 {
    grid-column: span 8;
  }

  .col-md-9 {
    grid-column: span 9;
  }

  .col-md-10 {
    grid-column: span 10;
  }

  .col-md-11 {
    grid-column: span 11;
  }

  .col-md-12 {
    grid-column: span 12;
  }
}

@media (max-width: 870px) {
  .col-gt-sm-12 {
    grid-column: span 12;
  }
}

@media (max-width: 767px) {
  .col-sm-1 {
    grid-column: span 1;
  }

  .col-sm-2 {
    grid-column: span 2;
  }

  .col-sm-3 {
    grid-column: span 3;
  }

  .col-sm-4 {
    grid-column: span 4;
  }

  .col-sm-5 {
    grid-column: span 5;
  }

  .col-sm-6 {
    grid-column: span 6;
  }

  .col-sm-7 {
    grid-column: span 7;
  }

  .col-sm-8 {
    grid-column: span 8;
  }

  .col-sm-9 {
    grid-column: span 9;
  }

  .col-sm-10 {
    grid-column: span 10;
  }

  .col-sm-11 {
    grid-column: span 11;
  }

  .col-sm-12 {
    grid-column: span 12;
  }
}

/*ESTILO PARA EL MODAL DE CIERRES DE CAJA*/
.custom-dialog {
  .mat-dialog-container {
    position: relative !important;
    padding: 0 !important;
  }
}


/*Elimina el borde derecho de algunos inputs y botones para hacer un input group*/
.input-group .mat-form-field-outline-end {
  border-radius: 0 0px 0px 0 !important;
  border-right: none !important;
}

.input-group-start .mat-form-field-outline-start {
  border-radius: 0 0px 0px 0 !important;
}


.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background: #0082c0;
}

.mb-1 {
  margin-bottom: 5px;
}

.bg-white {
  background-color: white;
}

.cursor-pointer {
  cursor: pointer;
}

.inline-container {
  display: flex;
}

.inline-container mat-checkbox {
  margin-right: 1em;
}

.inline-container mat-radio-group {
  margin-right: 1em;
}

.inline-container mat-radio-button {
  padding-right: 1em;
}

/*PLUGIN DE JIRA*/
iframe[name='JSD widget'] {
  display: block;
  left: 12px;
  bottom: 50px;
}

.max-height {
  overflow: auto;
  height: 380px !important;
}

/*Region totales*/

.flex-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  flex-wrap: wrap;
  margin-top: 10px;
}

.flex-items {
  width: 20rem;
  border: 1px solid #d8d8d8;
}

.total-align {
  padding-top: 20px;
}

.table-totales {
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 10px;
}

.table-totales td {
  padding: 5px 20px 5px 20px;
}

tr:not(:last-child) {
  border-bottom: 1px solid #d8d8d8;
}

.bg-montos {
  text-align: left;
  border-radius: 0 7px 7px 0;
  border-left: 1px solid #d8d8d8;
  font-weight: bold;
  color: #555555;
  width: 70%;
}

.bg-subtotal {
  text-align: left;
  border-radius: 7px 0 0 7px;
  width: 30%;
}

.label-total {
  text-align: center;
  border-radius: 0 7px 7px 0;
  font-size: 18px;
  font-weight: bold;
  color: #555555;
}

.div-total {
  margin-top: 10px;
  font-size: 2rem;
  font-weight: 600;
  height: 40px;
  position: static;
  text-align: center;
  border-radius: 0 7px 7px 0;
  color: #0082c0;
}

.hover-content {
  position: relative;
  display: inline-block;
}

.conversion-tooltip {
  display: none;
  background-color: #A4DEF9;
  color: black;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.5em;
  margin-right: 15px;
  vertical-align: middle;
  position: absolute;
  right: 100%;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.conversion-tooltip::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #A4DEF9;
}

.hover-content:hover .conversion-tooltip {
  display: block;
}


.shepherd-button {
  background: #3288e6;
  border: 0;
  border-radius: 3px;
  color: hsla(0, 0%, 100%, .75);
  cursor: pointer;
  margin-right: .5rem;
  padding: .5rem 1.5rem;
  transition: all .5s ease
}

.shepherd-button:not(:disabled):hover {
  background: #196fcc;
  color: hsla(0, 0%, 100%, .75)
}

.shepherd-button.shepherd-button-secondary {
  background: #f1f2f3;
  color: rgba(0, 0, 0, .75)
}

.shepherd-button.shepherd-button-secondary:not(:disabled):hover {
  background: #d6d9db;
  color: rgba(0, 0, 0, .75)
}

.shepherd-button:disabled {
  cursor: not-allowed
}

.shepherd-footer {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: flex-end;
  padding: 0 .75rem .75rem
}

.shepherd-footer .shepherd-button:last-child {
  margin-right: 0
}

.shepherd-cancel-icon {
  background: transparent;
  border: none;
  color: hsla(0, 0%, 50%, .75);
  cursor: pointer;
  font-size: 2em;
  font-weight: 400;
  margin: 0;
  padding: 0;
  transition: color .5s ease
}

.shepherd-cancel-icon:hover {
  color: rgba(0, 0, 0, .75)
}

shepherd-has-title .shepherd-content .shepherd-cancel-icon {
  color: hsla(0, 0%, 50%, .75)
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon:hover {
  color: rgba(0, 0, 0, .75)
}

.shepherd-title {
  color: rgba(0, 0, 0, 75%);
  display: flex;
  flex: 1 0 auto;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  padding: 0
}

.shepherd-header {
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: flex-end;
  line-height: 2em;
  padding: .75rem .75rem 0
}

.shepherd-has-title .shepherd-content .shepherd-header {
  background: #e6e6e6;
  padding: 1em
}

.shepherd-text {
  color: rgba(0, 0, 0, .75);
  font-size: 1rem;
  line-height: 1.3em;
  padding: .75em
}

.shepherd-text p {
  margin-top: 0
}

.shepherd-text p:last-child {
  margin-bottom: 0
}

.shepherd-content {
  border-radius: 5px;
  outline: none;
  padding: 0
}

.shepherd-element {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .2);
  max-width: 400px;
  opacity: 0;
  outline: none;
  transition: opacity .3s, visibility .3s;
  visibility: hidden;
  width: 100%;
  z-index: 9999
}

.shepherd-enabled.shepherd-element {
  opacity: 1;
  visibility: visible
}

.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered) {
  opacity: 0;
  pointer-events: none;
  visibility: hidden
}

.shepherd-element, .shepherd-element *, .shepherd-element :after, .shepherd-element :before {
  box-sizing: border-box
}

.shepherd-arrow, .shepherd-arrow:before {
  height: 16px;
  position: absolute;
  width: 16px;
  z-index: -1
}

.shepherd-arrow:before {
  background: #fff;
  content: "";
  transform: rotate(45deg)
}

.shepherd-element[data-popper-placement^=top] > .shepherd-arrow {
  bottom: -8px
}

.shepherd-element[data-popper-placement^=bottom] > .shepherd-arrow {
  top: -8px
}

.shepherd-element[data-popper-placement^=left] > .shepherd-arrow {
  right: -8px
}

.shepherd-element[data-popper-placement^=right] > .shepherd-arrow {
  left: -8px
}

.shepherd-element.shepherd-centered > .shepherd-arrow {
  opacity: 0
}

.shepherd-element.shepherd-has-title[data-popper-placement^=bottom] > .shepherd-arrow:before {
  background-color: #e6e6e6
}

.shepherd-target-click-disabled.shepherd-enabled.shepherd-target, .shepherd-target-click-disabled.shepherd-enabled.shepherd-target * {
  pointer-events: none
}

.shepherd-modal-overlay-container {
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all .3s ease-out, height 0ms .3s, opacity .3s 0ms;
  width: 100vw;
  z-index: 9997
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  height: 100vh;
  opacity: .5;
  transform: translateZ(0);
  transition: all .3s ease-out, height 0s 0s, opacity .3s 0s
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible path {
  pointer-events: all
}


.modal-actions-container {
  justify-content: center !important;
}

.modal-actions-container button.mat-flat-button.mat-button-base.mat-primary.ng-star-inserted {
  margin-right: 10px !important;
}


tr.mat-header-row {
  height: 56px;
  background-color: #fafbfb;
}

.ctrl-button {
  border: none;
  margin-top: 3.5px !important;
  height: 33px !important;
}

.search-button {
  margin-top: 0.2em !important;
  height: 35px;
}

//#region MODALES
.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 20px;

  .main {
    flex: 1;
  }

  .footer {
    width: 100%;
    margin-top: auto;
  }
}

//endregion


/*Boton a la par de un campo*/
.e-bill-search {
  margin-top: -5px !important;
  border-radius: 0 5px 5px 0 !important;
  background-color: #0082c0 !important;
  color: white !important;
  cursor: pointer;
  height: 34px;
}

.flex {
  display: flex;
  align-items: center;

  .mat-form-field {
    flex-grow: 1;
  }
}


/*Estilos para dispositivos móviles*/
@media only screen and (max-width: 600px) {
  .mat-table {
    min-width: 0 !important;
  }
  .mat-paginator-container {
    min-width: 0 !important;
  }
  .max-height {
    height: 600px !important;
  }
}

@media only screen and (max-width: 800px) {
  fieldset {
    padding: 10px 5px !important;
  }
}


/*Modales 100% width en movil*/

@media screen and (max-width: 450px) {
 .cdk-global-overlay-wrapper .cdk-overlay-pane {
      max-width: 700px !important;
      width: 98% !important;
      position: static !important;
    }
  .modal {
    max-width: 100vw !important;
  }


  .mat-option {
    font-size: inherit !important;;
    line-height: 1em !important;;
    height: 5em !important;;
    white-space: unset !important;;


  }
  .mat-option-text {
    line-height: 1em !important;
    white-space: unset !important;
    text-overflow: unset !important;
    word-break: break-word !important;
  }
}

@media screen and (max-width: 600px) {
  .mobile-tab .mat-tab-header {
    width:100% !important;
    margin-top: 40px;
    max-width: unset !important;
  }
  .mobile-margin {
    padding-top:  50px;;
  }
  .doc-margin {
    padding-top: 50px;
  }
  .double-margin {
    padding-top: 100px;
  }
}

tr {
  touch-action: auto !important;
}
